<template>
  <div class="end-page">
    <div class="message">
      <dl class="messageDl">
        <dt><img src="@/assets/切图 22.png" alt=""></dt>
        <dd>问卷到此结束,感谢评价!</dd>
      </dl>
    </div>
    <div class="handle">
      <div class="block">
        <van-button
          v-if="showNextBtn"
          type="info"
          class="handle-btn"
          @click="toNext"
        >
          下一个
        </van-button>
      </div>
      <div class="block">
        <van-button
          class="handle-btn"
          @click="$router.replace({name: 'home'})"
        >返回首页</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  questionnaireCheckNext,
} from "@/apis/mobileApi";
export default {
  data() {
    return {
      showNextBtn: false
    }
  },
  methods: {
    getPermission() {
      const {
        uid, username
      } = this.$route.query;
      questionnaireCheckNext({
        uid,
        username
      }).then(({ data }) => {
        this.showNextBtn = data;
      })
    },
    toNext() {
      const { qid } = this.$route.query;
      this.$router.replace(`/mobile/questionContent/${qid}/${0}`);
    }
  },
  mounted() {
    this.getPermission();
  }
}
</script>

<style lang="scss" scoped>
$zoom: 1020/ 375;
.message {
  font-size: 20px * $zoom;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  .messageDl{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    dt{
      margin-bottom: 20%;
    }
  }
}
.handle {
  padding: 0 40px * $zoom;

  .block {
    margin-bottom: 10px * $zoom;
  }

  .handle-btn {
    padding: 50px 0;
    width: 100%;
  }
}
</style>